/**
 * Customizes the fetch function to route requests to the appropriate BFF during local development.
 * 
 * Set the appropriate BFF URL with the REACT_APP_UPSIDESUPPORTBFF_URL environment variable in the
 * .env file during local development.
 * @param {*} url The URL to fetch.
 * @param {*} options The fetch options.
 * @returns The result of the fetch request.
 */
export function upsideFetch(url, options) {
  // During development, the UI may be run at a different host/port than the BFF.
  // In those cases, we overwrite the URL to route requests to the appropriate BFF.
  return fetch(overwriteUrl(url), options);
}

function overwriteUrl(url) {
  // This only applies during development.
  if (process.env.NODE_ENV !== "development") {
    return url;
  }

  // Only overwrite relative URLs.
  var isAbsolute = (url.startsWith("http://") || url.startsWith("https://"));
  if (isAbsolute) return url;

  var targetUrl = url;
  if (process.env.REACT_APP_UPSIDESUPPORTBFF_URL) {
    targetUrl = `${process.env.REACT_APP_UPSIDESUPPORTBFF_URL}${url}`;
  }

  return targetUrl;
}

export default {
  upsideFetch
};
